import './App.css';
import React from 'react';
import {Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/pages/Home';
import Contact from './components/pages/Contact';
import Gallery from './components/pages/Gallery';


function App() {
  return (
    <Router>
      <Navbar/>
      <Routes>
        <Route exact path='/' element={<Home/>} />
        <Route exact path='/contact' element={<Contact/>} />
        <Route exact path='/gallery' element={<Gallery/>} />
      </Routes>
    </Router>
  );
}

export default App;
