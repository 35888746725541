import React from 'react';
import img1 from '../../assets/images/collage.jpg';

function Gallery() {
    return (
        <div>
            <h1>
                Gallery Page
            </h1>

            <img src={img1} alt="img1" />
            
        </div>
    );
  }

export default Gallery;