import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { GiMorgueFeet } from 'react-icons/gi'
import { AiOutlineClose, AiOutlineMenu } from 'react-icons/ai'
import { Button } from './Button'
import './Navbar.css'
import { IconContext } from 'react-icons/lib'


function Navbar() {

     const [click, setClick] = useState(false)
     const [button, setButton] = useState(true)

     const handleClick = () => setClick(!click)
     const closeMobileMenu = () => setClick(false)

    const showButton = () => {
        if(window.innerWidth <= 960){
            setButton(false)
        } else {
            setButton(true)
        }
    }

    useEffect(() => {
        showButton();
    }, []);

    window.addEventListener('resize', showButton)

    return (
        <>
        <IconContext.Provider value={{color: '#fff'}}>
         <div className='navbar'>
             <div className='navbar-container container'>
                <Link to='/' className='navbar-logo' onClick={closeMobileMenu}>
                    <GiMorgueFeet className='navbar-icon' /> LittleFoot
                </Link>

                <div className="menu-icon" onClick={handleClick}>
                    {click ? <AiOutlineClose /> : <AiOutlineMenu />}
                </div>

                <ul className={click ? 'nav-menu active' : 'nav-menu'}>
                    <li className='nav-item'>
                        <Link to='/' className='nav-links' onClick={closeMobileMenu}>
                            Home
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/contact' className='nav-links' onClick={closeMobileMenu}>
                            Contact
                        </Link>
                    </li>
                    <li className='nav-item'>
                        <Link to='/gallery' className='nav-links' onClick={closeMobileMenu}>
                            Gallery
                        </Link>
                    </li>

                    <li className='nav-btn'>
                        {button ? (
                                <a href="tel:8632212271">
                                <Button buttonStyle='btn--outline' buttonSize='btn--small' href="tel:8632212271"> Call Now <br/>
                                        <a href="tel:8632212271" style={{color: '#f4d160', textDecoration: 'none'}} > (863) 221-2271</a>
                                </Button>
                                </a>
                        ) : (
                                <a href="tel:8632212271">
                                <Button buttonStyle='btn--outline'
                                        buttonSize='btn--wide'
                                        onClick={closeMobileMenu}> 
                                        Call Now <br/>
                                        <a href="tel:8632212271" style={{color: '#f4d160', textDecoration: 'none'}} > (863) 221-2271</a>
                                </Button>
                                </a>
                        )}
                    </li>

                </ul>
             </div>
         </div>
         </IconContext.Provider>
        </>
    )
}

export default Navbar
